import React from "react";
import {_getOnValueParams} from "../../../../services/FireBaseClientService";
import ID from "./ID";

export default class AreaDropDownList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            areas: [],
            cities: []
        }
    }

    componentDidMount() {
        if (this.props.defaultValue) {
            _getOnValueParams('/api/getData', {
                query: 'areasByArea',
                params: '\{\"area\":\"' + this.props.defaultValue + '\"\}'
            }, (res) => {
                console.log("areas", res.data.data)
                this.setState({
                    areas: res.data.data.areasByArea,
                    cities: res.data.data.cities
                })
            })
        } else {
            _getOnValueParams('/api/getData', {query: 'cities', params: '\{\}'}, (res) => {
                console.log("areas", res.data.data)
                this.setState({
                    cities: res.data.data.cities
                })
            })
        }
    }


    render() {
        return (
            <>
                <select {...this.props} onChange={this.onChange} id={"areas_list"}>
                    {this.state.areas && this.state.areas.length > 0 &&
                    this.state.areas.map(area =>
                        <option value={area.name} key={`preview_${ID.uuid()}`} className={"area-option"}
                                selected={this.props.defaultValue == area.name}>{area.name}</option>
                    )
                    }
                    {!this.state.areas && this.props.defaultValue &&
                    <option value={this.props.defaultValue} className={"area-option"}
                            key={`preview_${ID.uuid()}`}>{this.props.defaultValue}</option>
                    }

                    {
                        this.state.cities.map((city) => {
                            return city.areas.map(area => {
                                const areaKey = `preview_${ID.uuid()}`;
                                return <option className={'hidden area-option-' + city.name} data-id={area._id}
                                               value={area.name} key={areaKey}>{area.name}</option>;
                            })
                        })
                    }
                </select>
            </>
        );
    }

}