import React from "react";

export default class OldBoxNumberComponent extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        return <>
            <input {...this.props} id={"old_box_number"}/>
        </>;
    }
}