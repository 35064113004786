import React from "react";

export default class TotalNumberOfCurrency extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: null
        }
    }

    handleValueChange(value) {
        this.setState({
            value: value
        })
    }

    render() {
        let currentValue = this.state.value ? this.state.value * this.props.custom.weight : this.props.defaultValue ? this.props.defaultValue * this.props.custom.weight : 0;
        let countValue = this.state.value ? this.state.value : (this.props.defaultValue ? this.props.defaultValue : 0);
        let id  = "total_" + this.props.custom.weight;
        if (document.getElementById(id)) document.getElementById(id).value = currentValue;

        return <>
            <input {...this.props} type={"number"} defaultValue={countValue} onChange={(e) => {
                this.handleValueChange(e.target.value)
            }}/>
        </>;
    }
}