import {Registry} from "react-form-builder2";
import * as Inputs from "../components/shared/FormBuilderItmes";


const registerCustomComponents = () => {
    Registry.register('BoxNumber', Inputs.MyInput);
    Registry.register('CountNumberOfOneJOD', Inputs.TotalNumber);
    Registry.register('CountNumberOfFiveJOD', Inputs.TotalNumber);
    Registry.register('CountNumberOfTenJOD', Inputs.TotalNumber);
    Registry.register('CountNumberOfTwentyJOD', Inputs.TotalNumber);
    Registry.register('CountNumberOfFiftyJOD', Inputs.TotalNumber);
    Registry.register('TotalAmountInOneJOD', Inputs.TotalAmount);
    Registry.register('TotalAmountInFiveJOD', Inputs.TotalAmount);
    Registry.register('TotalAmountInTenJOD', Inputs.TotalAmount);
    Registry.register('TotalAmountInTwentyJOD', Inputs.TotalAmount);
    Registry.register('TotalAmountInFiftyJOD', Inputs.TotalAmount);
    Registry.register('TotalNumberOfPapers', Inputs.PaperCurrencyCount);
    Registry.register('Cities', Inputs.Cities);
    Registry.register('Areas', Inputs.Areas);
    Registry.register('TotalNumberOfCoins', Inputs.CoinsCurrencyCount);
    Registry.register('TotalNumberOfForeign', Inputs.ForeignCurrencyCount);
    Registry.register('TotalAmountInJod', Inputs.TotalAmountInJOD);
    Registry.register('RadioButtonsWithdrawal', Inputs.Withdrawal);
    Registry.register('BrinksRadioButtonsWithdrawal', Inputs.BrinksWithdrawal);
    Registry.register('NewBoxNumber', Inputs.NewBoxNumberComponent);
    Registry.register('BrinksVisitAmount', Inputs.BrinksVisitComponent);
    Registry.register('BrinksCollectionAmount', Inputs.BrinksCollectionComponent);
    Registry.register('BrinksTotalAmount', Inputs.BrinksTotalAmount);
    Registry.register('OldBoxNumber', Inputs.OldBoxNumberComponentInput);
    Registry.register('NextCollectionDate', Inputs.NextCollectionDate);
    Registry.register('FromDate', Inputs.FromDate);
    Registry.register('ToDate', Inputs.ToDate);
    Registry.register('Semesters', Inputs.Semesters);
    Registry.register('Clients', Inputs.Clients);
    Registry.register('TotalAmountInJod1', Inputs.ForeignTotal1);
    Registry.register('TotalAmountInJod2', Inputs.ForeignTotal2);
    Registry.register('TotalAmountInJod3', Inputs.ForeignTotal3);
    Registry.register('TotalAmountInJod4', Inputs.ForeignTotal4);
    Registry.register('TotalAmountInJod5', Inputs.ForeignTotal5);
    Registry.register('TotalAmountInJod6', Inputs.ForeignTotal6);
    Registry.register('TotalAmountInJod7', Inputs.ForeignTotal7);
    Registry.register('TotalAmountInJod8', Inputs.ForeignTotal8);
    Registry.register('TotalAmountInJod9', Inputs.ForeignTotal9);
    Registry.register('TotalAmountInJod10', Inputs.ForeignTotal10);
    Registry.register('TotalAmountInJod11', Inputs.ForeignTotal11);
    Registry.register('TotalAmountInJod12', Inputs.ForeignTotal12);
    Registry.register('TotalAmountInJod13', Inputs.ForeignTotal13);
    Registry.register('TotalAmountInJod14', Inputs.ForeignTotal14);
    Registry.register('TotalAmountInJod15', Inputs.ForeignTotal15);
    Registry.register('ForeignCurrenciesControl', Inputs.ForeignCurrenciesControl);
    Registry.register('ForeignCurrenciesList1', Inputs.ForeignCurrenciesListComponent1);
    Registry.register('ForeignCurrenciesList2', Inputs.ForeignCurrenciesListComponent2);
    Registry.register('ForeignCurrenciesList3', Inputs.ForeignCurrenciesListComponent3);
    Registry.register('ForeignCurrenciesList4', Inputs.ForeignCurrenciesListComponent4);
    Registry.register('ForeignCurrenciesList5', Inputs.ForeignCurrenciesListComponent5);
    Registry.register('ForeignCurrenciesList6', Inputs.ForeignCurrenciesListComponent6);
    Registry.register('ForeignCurrenciesList7', Inputs.ForeignCurrenciesListComponent7);
    Registry.register('ForeignCurrenciesList8', Inputs.ForeignCurrenciesListComponent8);
    Registry.register('ForeignCurrenciesList9', Inputs.ForeignCurrenciesListComponent9);
    Registry.register('ForeignCurrenciesList10', Inputs.ForeignCurrenciesListComponent10);
    Registry.register('ForeignCurrenciesList11', Inputs.ForeignCurrenciesListComponent11);
    Registry.register('ForeignCurrenciesList12', Inputs.ForeignCurrenciesListComponent12);
    Registry.register('ForeignCurrenciesList13', Inputs.ForeignCurrenciesListComponent13);
    Registry.register('ForeignCurrenciesList14', Inputs.ForeignCurrenciesListComponent14);
    Registry.register('ForeignCurrenciesList15', Inputs.ForeignCurrenciesListComponent15);
    Registry.register('ForeignCurrencyTransferRate1', Inputs.ForeignCurrencyTransferRate2);
    Registry.register('ForeignCurrencyTransferRate2', Inputs.ForeignCurrencyTransferRate2);
    Registry.register('ForeignCurrencyTransferRate3', Inputs.ForeignCurrencyTransferRate3);
    Registry.register('ForeignCurrencyTransferRate4', Inputs.ForeignCurrencyTransferRate4);
    Registry.register('ForeignCurrencyTransferRate5', Inputs.ForeignCurrencyTransferRate5);
    Registry.register('ForeignCurrencyTransferRate6', Inputs.ForeignCurrencyTransferRate6);
    Registry.register('ForeignCurrencyTransferRate7', Inputs.ForeignCurrencyTransferRate7);
    Registry.register('ForeignCurrencyTransferRate8', Inputs.ForeignCurrencyTransferRate8);
    Registry.register('ForeignCurrencyTransferRate9', Inputs.ForeignCurrencyTransferRate9);
    Registry.register('ForeignCurrencyTransferRate10', Inputs.ForeignCurrencyTransferRate10);
    Registry.register('ForeignCurrencyTransferRate11', Inputs.ForeignCurrencyTransferRate11);
    Registry.register('ForeignCurrencyTransferRate12', Inputs.ForeignCurrencyTransferRate12);
    Registry.register('ForeignCurrencyTransferRate13', Inputs.ForeignCurrencyTransferRate13);
    Registry.register('ForeignCurrencyTransferRate14', Inputs.ForeignCurrencyTransferRate14);
    Registry.register('ForeignCurrencyTransferRate15', Inputs.ForeignCurrencyTransferRate15);
    Registry.register('BoxSizeRadio', Inputs.CustomRadio);
    Registry.register('BoxBaseRadio', Inputs.BoxBaseCustomRadio);
    Registry.register('walletNumber', Inputs.WalletNumber);
    Registry.register('Attendance', Inputs.Attendance);
    Registry.register('MainProfRepValue', Inputs.MainProfRepFieldValue);
    Registry.register('MainDonorName', Inputs.MainDonorNameInput);
    Registry.register('ApproximatedAmount', Inputs.ApproximatedAmountInput);
    Registry.register('DoubleNumberInput', Inputs.DoubleNumberInput);
}

export {registerCustomComponents}