import React from "react";

export default class TotalNumberOfPaperCurrency extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: null
        }
    }

    componentDidMount() {
        let values = this.props.custom.values;
        let total = 0;
        values.forEach(value => {
            if (document.getElementById(value)) total += Number(document.getElementById(value).value) ;
        })

        console.log(total)

    }

    render() {

        return <>
            <input {...this.props} id={this.props.id}/>

        </>;
    }
}