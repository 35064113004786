import React from "react";
import ID from "./ID";

export default class ForeignCurrenciesControlDropdownList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            controls: [0,1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        }
    }

    onChange(e) {
        let value = e.target.value;

        for (var i = 1; i <= 15; i++) {
            if (i <= value) {
                document.querySelectorAll(".foreign-currency-list" + i).forEach(e => {
                    e.parentNode.parentNode.classList.remove("hidden");
                    e.classList.remove("hidden");
                });

                document.querySelectorAll(".total_amount_in_jod_input_" + i).forEach(e => {
                    e.parentNode.parentNode.classList.remove("hidden");
                    e.classList.remove("hidden");
                });

                document.querySelectorAll(".foreign_currency_transfer_rate_" + i).forEach(e => {
                    e.parentNode.parentNode.classList.remove("hidden");
                    e.classList.remove("hidden");
                });
            } else {
                document.querySelectorAll(".foreign-currency-list" + i).forEach(e => {
                    let isEditMode = e.parentNode.parentNode.parentNode.getAttribute('draggable')
                    if (!isEditMode) {
                        e.parentNode.parentNode.classList.add("hidden");
                        e.value = "";
                    }
                });

                document.querySelectorAll(".total_amount_in_jod_input_" + i).forEach(e => {
                    let isEditMode = e.parentNode.parentNode.parentNode.getAttribute('draggable')
                    if (!isEditMode) {
                        e.parentNode.parentNode.classList.add("hidden");
                        e.value = "";
                    }
                });

                document.querySelectorAll(".foreign_currency_transfer_rate_" + i).forEach(e => {
                    let isEditMode = e.parentNode.parentNode.parentNode.getAttribute('draggable')
                    if (!isEditMode) {
                        e.parentNode.parentNode.classList.add("hidden");
                        e.value = "";
                    }
                });
            }
        }
    }

    componentDidMount() {
        let defaultValue = this.props.defaultValue;
        if (defaultValue && defaultValue > 0) {
            for (var i = 1; i <= 15; i++) {
                if (i <= defaultValue) {
                    document.querySelectorAll(".foreign-currency-list" + i).forEach(e => {
                        e.parentNode.parentNode.classList.remove("hidden");
                        e.classList.remove("hidden");
                    });

                    document.querySelectorAll(".total_amount_in_jod_input_" + i).forEach(e => {
                        e.parentNode.parentNode.classList.remove("hidden");
                        e.classList.remove("hidden");
                    });

                    document.querySelectorAll(".foreign_currency_transfer_rate_" + i).forEach(e => {
                        e.parentNode.parentNode.classList.remove("hidden");
                        e.classList.remove("hidden");
                    });
                } else {
                    document.querySelectorAll(".foreign-currency-list" + i).forEach(e => {
                        let isEditMode = e.parentNode.parentNode.parentNode.getAttribute('draggable')
                        if (!isEditMode) e.parentNode.parentNode.classList.add("hidden");
                    });

                    document.querySelectorAll(".total_amount_in_jod_input_" + i).forEach(e => {
                        let isEditMode = e.parentNode.parentNode.parentNode.getAttribute('draggable')
                        if (!isEditMode) e.parentNode.parentNode.classList.add("hidden");
                    });

                    document.querySelectorAll(".foreign_currency_transfer_rate_" + i).forEach(e => {
                        let isEditMode = e.parentNode.parentNode.parentNode.getAttribute('draggable')
                        if (!isEditMode) e.parentNode.parentNode.classList.add("hidden");
                    });
                }
            }
        } else {
            for (var i = 1; i <= 15; i++) {
                document.querySelectorAll(".foreign-currency-list" + i).forEach(e => {
                    let isPreview = e.closest(".react-form-builder-preview");
                    if (!isPreview) e.parentNode.parentNode.classList.add("hidden");
                    console.log("isPreview",isPreview)
                    console.log("grandfather",e.parentNode.parentNode.parentNode)

                });

                document.querySelectorAll(".total_amount_in_jod_input_" + i).forEach(e => {
                    let isPreview = e.closest(".react-form-builder-preview");
                    if (!isPreview) e.parentNode.parentNode.classList.add("hidden");
                    console.log("isPreview",isPreview)
                    console.log("grandfather",e.parentNode.parentNode.parentNode)

                });

                document.querySelectorAll(".foreign_currency_transfer_rate_" + i).forEach(e => {
                    let isPreview = e.closest(".react-form-builder-preview");
                    if (!isPreview) e.parentNode.parentNode.classList.add("hidden");
                    console.log("isPreview",isPreview)
                    console.log("grandfather",e.parentNode.parentNode.parentNode)

                });
            }
        }
    }

    render() {

        return (
            <>
                <select {...this.props} onChange={this.onChange} id={"foreign_currencies_control_list"}>
                    {this.state.controls.map((control) => {
                        const this_key = `foreign_${ID.uuid()}`;
                        return <option value={control} key={this_key}
                                       selected={this.props.defaultValue == control}>{control}</option>;
                    })}
                </select>
            </>
        );
    }
}