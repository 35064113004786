import React from "react";
import {_getOnValueParams} from "../../../../services/FireBaseClientService";
import ID from "./ID";
import $ from 'jquery';


let client = null;

export default class ClientsDropDownList extends React.Component {


    constructor(props) {
        super(props)
        this.state = {
            cities: [],
        }
    }

    componentDidMount() {
        _getOnValueParams('/api/getData', {query: 'clients', params: '\{\}'}, (res) => {
            this.setState({
                clients: res.data.data.clients
            })
        })
    }

    onChange(e) {
        let option = $(e.target).find("option[value='"+e.target.value+"']");
        $('#full_name_').val(e.target.value)
        $('#phone_number_').val(option.data("phone"))
        $('#city_').val(option.data("city"))
        $('#bank_').val(option.data("bank"))
        $('#iban_').val(option.data("iban"))
        $('.client-meta').show();
    }


    render() {
        let currentValue = this.props.defaultValue;
        let client = currentValue && this.state.clients ? this.state.clients.filter(c => c.fullName == currentValue)[0] : {};
        console.log(client)
        return (
            <>
                <select {...this.props} onChange={this.onChange} id={"clients_list"}>
                    <option value="" key={0}></option>
                    {this.state.clients && this.state.clients.map((tClient) => {
                        const this_key = `preview_${ID.uuid()}`;
                        return <option value={tClient.fullName} key={this_key}
                                       data-phone={tClient.mobileNumber}
                                       data-city={tClient.city}
                                       data-iban={tClient.iban}
                                       data-bank={tClient.bank}
                                       selected={client.fullName == tClient.fullName}>{tClient.fullName}</option>;
                    })}
                </select>

                <br/>

                <div className={"client-meta form-group "  + (client ?"" :"hidden")}>
                    <label className="form-label">
                        <span>City</span>
                    </label>
                    <input id={"city_"} type={"text"} name={"city_"} disabled={true}
                           defaultValue={client.city}
                           className={"form-control"}/>
                </div>

                <div className={"client-meta form-group" + (client ?"" :"hidden")}>
                    <label className="form-label">
                        <span>Bank</span>
                    </label>
                    <input id={"bank_"} type={"text"} name={"bank_"} disabled={true}
                           defaultValue={client.bank}
                           className={"form-control"}/>
                </div>

                <div className={"client-meta form-group " + (client ?"" :"hidden")}>
                    <label className="form-label">
                        <span>Iban</span>
                    </label>
                    <input id={"iban_"} type={"text"} name={"iban_"} disabled={true}
                           defaultValue={client.iban}
                           className={"form-control"}/>
                </div>

                <div className={"client-meta form-group " + (client ?"" :"hidden")}>
                    <label className="form-label">
                        <span>Phone Number</span>
                    </label>
                    <input id={"phone_number_"} type={"text"} name={"phone_number_"} disabled={true}
                           defaultValue={client.mobileNumber}
                           className={"form-control"}/>
                </div>
            </>
        );
    }
}