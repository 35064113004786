import React from "react";
import {Col, Row} from "react-bootstrap";
import {
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    ModalHeader,
    UncontrolledDropdown
} from "reactstrap";
import {AvForm} from "availity-reactstrap-validation";
import AutoSuggestStakeholder from "../../stackholders/AutoSuggestStakeholder";
import classNames from "classnames";
import {_getOnValueParams} from "../../../../services/FireBaseClientService";
import FormControl from "@material-ui/core/FormControl";

export default class BoxNumberComponent extends React.Component {

    constructor(props) {
        super(props);
        let type = "donation_boxes";
        this.state = {
            modal: [false],
            type: type ? type : "",
            boxNumber: "",
            dataField: "radiobuttons_1FD08D01-D5D5-4B7E-844E-B9EE5EF0DB91"
        }
    }

    componentDidMount() {
        this.fetchData(this.state.type);
    }


    toggle = (idx) => {
        let modals = this.state.modal.slice();
        modals[idx] = !modals[idx];
        this.setState({
            modal: modals
        })
    }


    fetchData = (type) => {
        _getOnValueParams("/api/getData", {
            query: "regForm",
            params: "\{\"type\":" + type.replaceAll(" ", "_") + "\}"
        }, (response) => {
            let regForm = response.data.data.form;
            this.setState({
                type: type,
                representationFields: regForm ? regForm.representationFields : [],
                regForm:regForm
            })
        })
    }

    handleBoxing = (values) => {
        this.setBoxNumber(values);
    }

    setBoxNumber = (data) => {
        this.toggle(1);
        this.setState({
            boxNumber: data
        })
    }


    onSearchFieldSelected(option) {
        this.setState({
            searchField: option
        })
    }

    getSearchFieldOptions() {
        let columns = this.state.regForm ? this.state.regForm.columns : [];
        let statusField = columns.filter(column => column.text.startsWith('Box Status'))[0];
        if (statusField)
            return statusField.options;

        return [];
    }

    render() {

        const Filter = (props) => <FormControl>
            <UncontrolledDropdown className="d-inline">
                <DropdownToggle caret tag="button" type="button"
                                className="btn btn-outline-secondary btn-sm">
                    {this.state.searchField ? this.state.searchField.text : "Select Box Status to Filter"}
                </DropdownToggle>
                <DropdownMenu>
                    {this.getSearchFieldOptions().map((option, idx) => (
                        <DropdownItem
                            key={idx}
                            type="button"
                            onClick={() => this.onSearchFieldSelected(option)}>
                            {option.text}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledDropdown>
        </FormControl>;

        let disabledProps = {};
        disabledProps.disabled = this.props.disabled;

        console.log("disabledProps", disabledProps)

        return <>
            <input {...this.props}
                   defaultValue={this.state.boxNumber ? this.state.boxNumber : this.props.defaultValue}/>

            <button {...disabledProps} className={"mb-2 mr-1 btn btn-success pull-right " + (disabledProps && disabledProps.disabled == "disabled" ? 'hidden':'')} onClick={(event) => {
                event.preventDefault();
                this.toggle(1)
            }}><i className="uil uil-search"></i></button>

            <Modal isOpen={this.state.modal[1]}
                   size={'lg'}
                   toggle={() => this.toggle(1)}>
                <ModalHeader toggle={() => this.toggle(1)}>
                    Find Donation Box
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <Row>
                                <Filter/>
                            </Row>
                            <Row>
                                <AvForm
                                    onValidSubmit={(e, values) => {
                                        e.preventDefault();
                                        this.handleBoxing(values)
                                    }}>
                                    {this.state.representationFields && this.state.representationFields.filter((item, idx) => idx == 0).map((field, idx) => {

                                        return (
                                            <AutoSuggestStakeholder
                                                key={idx}
                                                searchFilter={this.state.dataField}
                                                searchFilterValue={this.state.searchField ? this.state.searchField.key:  ""}
                                                onNameChange={(data) => this.setBoxNumber(data)}
                                                type={this.state.type}
                                                recordedStakeholder={[]}
                                                action={"boxing"}
                                                field={field}/>

                                        )
                                    })}

                                    <Button {...disabledProps} type="submit" className={(disabledProps && disabledProps.disabled == "disabled" ? 'hidden':'')} onClick={(event) => {
                                        event.preventDefault();
                                        this.toggle(1)
                                    }}>
                                        <i className={classNames('mdi', 'mdi-content-save', 'ml-1', 'mr-1')}></i>
                                        Save
                                    </Button>

                                </AvForm>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>;
    }
}