import React from "react";
import {_getOnValueParams} from "../../../../services/FireBaseClientService";
import ID from "./ID";

let city = null;

export default class CityDropDownList extends React.Component {


    constructor(props) {
        super(props)
        this.state = {
            cities: [],
        }
    }

    componentDidMount() {
        _getOnValueParams('/api/getData', {query: 'cities', params: '\{\}'}, (res) => {
            console.log(res.data.data)
            this.setState({
                cities: res.data.data.cities
            })
        })
    }

    onChange(e) {
        document.querySelectorAll(".area-option").forEach(e => {
            e.classList.add("hidden")
        });


        if (city)
            document.querySelectorAll(".area-option-" + city).forEach(e => {
                e.classList.add("hidden")
            });

        document.querySelectorAll(".area-option-" + e.target.value).forEach(e => {
            e.classList.remove("hidden")
        });

        document.getElementById("areas_list").value = null

        city = e.target.value
    }


    render() {
        return (
            <>
                <select {...this.props} onChange={this.onChange} id={"cities_list"}>
                    {this.state.cities.map((city) => {
                        const this_key = `preview_${ID.uuid()}`;
                        return <option value={city.name} key={this_key}
                                       selected={this.props.defaultValue == city.name}>{city.name}</option>;
                    })}
                </select>
            </>
        );
    }
}