import React from "react";
import ID from "./ID";

export default class CustomRadioButtons extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: null
        }
    }

    render() {
        let self = this;

        const props = {};
        props.name = self.props.data.field_name;

        let value = this.state.value ? this.state.value : self.props.defaultValue ? self.props.defaultValue : "";

        props.value = value;

        props.defaultChecked = (self.props.defaultValue !== undefined &&
            (self.props.defaultValue.indexOf(this.state.value) > -1));

        let disabledProps = {};
        disabledProps.disabled = this.props.disabled;

        return <>

            <input type={"hidden"} ref={this.props.ref} {...props} id={this.props.id}/>

            <div>
                {this.props.data.options.map((option) => {
                    let key = ID.uuid();
                    const this_key = `${this.props.id}_preview_${key}`;
                    let dc = (value &&
                        (value.indexOf(option.value) > -1))
                    return (
                        <div key={this_key} className={"custom-control custom-radio" + (this.props.inline  ? " option-inline ": "")}>
                            <input id={"fid_" + this_key} className="custom-control-input" type={'radio'}
                                   {...disabledProps}
                                   value={option.value} name={this.props.id + "_custom_radio"} defaultChecked={dc}/>
                            <label className="custom-control-label" htmlFor={"fid_" + this_key}>{option.text}</label>
                        </div>
                    );
                })}
            </div>
        </>;
    }
}