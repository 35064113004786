import React from "react";

export default class TotalNumberOfForeignCurrency extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let defaultValue = this.props.defaultValue ? this.props.defaultValue : 0;
        return <>
            <input {...this.props} id={"total_foreign"} defaultValue={defaultValue}/>
        </>;
    }
}