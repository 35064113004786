import React from "react";
import ID from "./ID";

export default class ForeignCurrenciesList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currencies: ["JOD","USD"],
        }
    }

    onChange(e) {
    }

    componentDidMount() {
    }

    render() {

        return (
            <>
                <select {...this.props} onChange={this.onChange} id={"foreign_currencies_list"}>
                    {this.state.currencies.map((control) => {
                        const this_key = `foreign_currency_${ID.uuid()}`;
                        return <option value={control} key={this_key}
                                       selected={this.props.defaultValue == control}>{control}</option>;
                    })}
                </select>
            </>
        );
    }
}