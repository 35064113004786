import React from "react";
import BoxNumberComponent from "../page/formBuilder/customComponents/BoxNumberComponent";
import TotalNumberOfCurrency from "../page/formBuilder/customComponents/TotalNumberOfCurrency";
import TotalNumberOfPaperCurrency from "../page/formBuilder/customComponents/TotalNumberOfPaperCurrency";
import CityDropDownList from "../page/formBuilder/customComponents/CityDropDownList";
import AreaDropDownList from "../page/formBuilder/customComponents/AreaDropDownList";
import TotalNumberOfCoinsCurrency from "../page/formBuilder/customComponents/TotalNumberOfCoinsCurrency";
import TotalNumberOfForeignCurrency from "../page/formBuilder/customComponents/TotalNumberOfForeignCurrency";
import WithdrawalRadioButtons from "../page/formBuilder/customComponents/WithdrawalRadioButtons";
import CustomizedInput from "../page/formBuilder/customComponents/CustomizedInput";
import OldBoxNumberComponent from "../page/formBuilder/customComponents/OldBoxNumberComponent";
import ClientsDropDownList from "../page/formBuilder/customComponents/ClientsDropDownList";
import SemestersDropDownList from "../page/formBuilder/customComponents/SemestersDropDownList";
import ForeignCurrenciesControlDropdownList
    from "../page/formBuilder/customComponents/ForeignCurrenciesControlDropdownList";
import ForeignCurrenciesList from "../page/formBuilder/customComponents/ForeignCurrenciesList";
import CustomRadioButtons from "../page/formBuilder/customComponents/CustomRadioButtons";
import ServicesDropDownList from "../page/formBuilder/customComponents/ServicesDropDownList";

const MyInput = React.forwardRef((props, ref) => {
    let data = props.data;
    return <BoxNumberComponent data={data} {...props} ref={ref}/>;
});

const NewBoxNumberComponent = React.forwardRef((props, ref) => {
    let data = props.data;
    return <BoxNumberComponent data={data} {...props} ref={ref}/>;
});

const OldBoxNumberComponentInput = React.forwardRef((props, ref) => {
    let data = props.data;
    return <OldBoxNumberComponent data={data} {...props} ref={ref}/>;
});

const ApproximatedAmountInput = React.forwardRef((props, ref) => {
    let data = props.data;
    return <CustomizedInput data={data} {...props} ref={ref}/>;
});

const TotalNumber = React.forwardRef((props, ref) => {
    let data = props.data;
    return <TotalNumberOfCurrency data={data} {...props} ref={ref}/>
})

const TotalAmount = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const DoubleNumberInput = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const PaperCurrencyCount = React.forwardRef((props, ref) => {
    return <TotalNumberOfPaperCurrency {...props} ref={ref}/>
})

const CoinsCurrencyCount = React.forwardRef((props, ref) => {
    return <TotalNumberOfCoinsCurrency {...props} ref={ref}/>
})

const ForeignCurrencyCount = React.forwardRef((props, ref) => {
    return <TotalNumberOfForeignCurrency {...props} ref={ref}/>
})


const TotalAmountInJOD = React.forwardRef((props, ref) => {
    return <TotalNumberOfPaperCurrency {...props} ref={ref}/>
})

const Cities = React.forwardRef((props, ref) => {
    return <CityDropDownList {...props} ref={ref}/>
})

const Clients = React.forwardRef((props, ref) => {
    return <ClientsDropDownList {...props} ref={ref}/>
})

const Semesters = React.forwardRef((props, ref) => {
    return <SemestersDropDownList {...props} ref={ref}/>
})

const Areas = React.forwardRef((props, ref) => {
    return <AreaDropDownList {...props} ref={ref}/>
})

const Withdrawal = React.forwardRef((props, ref) => {
    return <WithdrawalRadioButtons {...props} ref={ref}/>
})

const CustomRadio = React.forwardRef((props, ref) => {
    return <CustomRadioButtons {...props} ref={ref}/>
})

const BoxBaseCustomRadio = React.forwardRef((props, ref) => {
    return <CustomRadioButtons {...props} ref={ref}/>
})

const BrinksWithdrawal = React.forwardRef((props, ref) => {
    return <WithdrawalRadioButtons {...props} ref={ref}/>
})

const BrinksVisitComponent = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const BrinksCollectionComponent = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const NextCollectionDate = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const FromDate = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ToDate = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const BrinksTotalAmount = React.forwardRef((props, ref) => {
    return <TotalNumberOfPaperCurrency {...props} ref={ref}/>
})

const ForeignCurrenciesControl = React.forwardRef((props, ref) => {
    return <ForeignCurrenciesControlDropdownList {...props} ref={ref}/>
})

const ForeignCurrenciesListComponent1 = React.forwardRef((props, ref) => {
    return <ForeignCurrenciesList {...props} ref={ref}/>
})

const ForeignCurrenciesListComponent2 = React.forwardRef((props, ref) => {
    return <ForeignCurrenciesList {...props} ref={ref}/>
})
const ForeignCurrenciesListComponent3 = React.forwardRef((props, ref) => {
    return <ForeignCurrenciesList {...props} ref={ref}/>
})
const ForeignCurrenciesListComponent4 = React.forwardRef((props, ref) => {
    return <ForeignCurrenciesList {...props} ref={ref}/>
})
const ForeignCurrenciesListComponent5 = React.forwardRef((props, ref) => {
    return <ForeignCurrenciesList {...props} ref={ref}/>
})
const ForeignCurrenciesListComponent6 = React.forwardRef((props, ref) => {
    return <ForeignCurrenciesList {...props} ref={ref}/>
})
const ForeignCurrenciesListComponent7 = React.forwardRef((props, ref) => {
    return <ForeignCurrenciesList {...props} ref={ref}/>
})
const ForeignCurrenciesListComponent8 = React.forwardRef((props, ref) => {
    return <ForeignCurrenciesList {...props} ref={ref}/>
})
const ForeignCurrenciesListComponent9 = React.forwardRef((props, ref) => {
    return <ForeignCurrenciesList {...props} ref={ref}/>
})
const ForeignCurrenciesListComponent10 = React.forwardRef((props, ref) => {
    return <ForeignCurrenciesList {...props} ref={ref}/>
})
const ForeignCurrenciesListComponent11 = React.forwardRef((props, ref) => {
    return <ForeignCurrenciesList {...props} ref={ref}/>
})
const ForeignCurrenciesListComponent12 = React.forwardRef((props, ref) => {
    return <ForeignCurrenciesList {...props} ref={ref}/>
})
const ForeignCurrenciesListComponent13 = React.forwardRef((props, ref) => {
    return <ForeignCurrenciesList {...props} ref={ref}/>
})
const ForeignCurrenciesListComponent14 = React.forwardRef((props, ref) => {
    return <ForeignCurrenciesList {...props} ref={ref}/>
})
const ForeignCurrenciesListComponent15 = React.forwardRef((props, ref) => {
    return <ForeignCurrenciesList {...props} ref={ref}/>
})

const ForeignCurrencyTransferRate1 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignCurrencyTransferRate2 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignCurrencyTransferRate3 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignCurrencyTransferRate4 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignCurrencyTransferRate5 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignCurrencyTransferRate6 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignCurrencyTransferRate7 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignCurrencyTransferRate8 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignCurrencyTransferRate9 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignCurrencyTransferRate10 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignCurrencyTransferRate11 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignCurrencyTransferRate12 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignCurrencyTransferRate13 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignCurrencyTransferRate14 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignCurrencyTransferRate15 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})


const ForeignTotal1 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignTotal2 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignTotal3 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignTotal4 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignTotal5 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignTotal6 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignTotal7 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignTotal8 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignTotal9 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignTotal10 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignTotal11 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignTotal12 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignTotal13 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignTotal14 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const ForeignTotal15 = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})


const WalletNumber = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const Attendance = React.forwardRef((props, ref) => {
    return <ServicesDropDownList {...props} ref={ref}/>
})

const MainProfRepFieldValue = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})

const MainDonorNameInput = React.forwardRef((props, ref) => {
    return <CustomizedInput {...props} ref={ref}/>
})


const formBuilderItmes = [
    {
        key: 'Header',
        name: 'Header Text',
        icon: 'fa fa-header',
        "static": true,
        content: 'Placeholder Text...'
    }, {
        key: 'Label',
        name: 'Label',
        "static": true,
        icon: 'fa fa-font',
        content: 'Placeholder Text...'
    }, {
        key: 'Paragraph',
        name: 'Paragraph',
        "static": true,
        icon: 'fa fa-paragraph',
        content: 'Placeholder Text...'
    }, {
        key: 'LineBreak',
        name: 'Line Break',
        "static": true,
        icon: 'fa fa-arrows-h'
    }, {
        key: 'Dropdown',
        canHaveAnswer: true,
        name: 'Dropdown',
        icon: 'fa fa-caret-square-o-down',
        label: 'Placeholder Label',
        field_name: 'dropdown_',
        options: []
    }, {
        key: 'Tags',
        canHaveAnswer: true,
        name: 'Tags',
        icon: 'fa fa-tags',
        label: 'Placeholder Label',
        field_name: 'tags_',
        options: []
    }, {
        key: 'Checkboxes',
        canHaveAnswer: true,
        name: 'Checkboxes',
        icon: 'fa fa-check-square-o',
        label: 'Placeholder Label',
        field_name: 'checkboxes_',
        options: []
    }, {
        key: 'RadioButtons',
        canHaveAnswer: true,
        name: 'Multiple Choice',
        icon: 'fa fa-dot-circle-o',
        label: 'Placeholder Label',
        field_name: 'radiobuttons_',
        options: []
    }, {
        key: 'TextInput',
        canHaveAnswer: true,
        name: 'Text Input',
        label: 'Placeholder Label',
        icon: 'fa fa-font',
        field_name: 'text_input_'
    }, {
        key: 'NumberInput',
        canHaveAnswer: true,
        name: 'Number Input',
        label: 'Placeholder Label',
        icon: 'fa fa-plus',
        field_name: 'number_input_'
    },
    ,
        {
            key: 'DoubleNumberInput',
            element: 'CustomElement',
            component: DoubleNumberInput,
            type: 'custom',
            canHaveAnswer: true,
            forwardRef: true,
            field_name: 'double_number_input_',
            name: 'Double Number Input',
            icon: 'fa fa-plus',
            props: {
                defaultValue: 0,
                type: "number",
                step: "any",
                className: "form-control"
            },
            label: 'Double Number Input',
        }, {
        key: 'TextArea',
        canHaveAnswer: true,
        name: 'Multi-line Input',
        label: 'Placeholder Label',
        icon: 'fa fa-text-height',
        field_name: 'text_area_'
    }, {
        key: 'Image',
        name: 'Image',
        label: '',
        icon: 'fa fa-photo',
        field_name: 'image_',
        src: ''
    }, {
        key: 'Rating',
        canHaveAnswer: true,
        name: 'Rating',
        label: 'Placeholder Label',
        icon: 'fa fa-star',
        field_name: 'rating_'
    }, {
        key: 'DatePicker',
        canDefaultToday: true,
        canReadOnly: true,
        dateFormat: 'yyyy/MM/dd',
        timeFormat: 'hh:mm aa',
        showTimeSelect: false,
        showTimeSelectOnly: false,
        name: 'Date',
        icon: 'fa fa-calendar',
        label: 'Placeholder Label',
        field_name: 'date_picker_'
    },
    {
        key: 'NextCollectionDate',
        element: 'CustomElement',
        component: NextCollectionDate,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'next_collection_date_input_',
        name: 'Next Collection Date',
        icon: 'fa fa-calendar',
        props: {
            type: "text",
            className: "form-control",
            id: "nextDateCollection",
            disabled: true,
            placeholder: "yyyy/mm/dd"
        },
        label: 'Next Collection Date',
    },
    {
        key: 'FromDate',
        element: 'CustomElement',
        component: FromDate,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'from_date_input_',
        name: 'From Date',
        icon: 'fa fa-calendar',
        props: {type: "text", className: "form-control", id: "fromDate", disabled: true, placeholder: "yyyy/mm/dd"},
        label: 'From Date',
    },
    {
        key: 'ToDate',
        element: 'CustomElement',
        component: ToDate,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'to_date_input_',
        name: 'To Date',
        icon: 'fa fa-calendar',
        props: {type: "text", className: "form-control", id: "toDate", disabled: true, placeholder: "yyyy/mm/dd"},
        label: 'To Date',
    },
    {
        key: 'Signature',
        canReadOnly: true,
        name: 'Signature',
        icon: 'fa fa-pencil-square-o',
        label: 'Signature',
        field_name: 'signature_'
    }, {
        key: 'HyperLink',
        name: 'Web site',
        icon: 'fa fa-link',
        "static": true,
        content: 'Placeholder Web site link ...',
        href: 'http://www.example.com'
    }, {
        key: 'Download',
        name: 'File Attachment',
        icon: 'fa fa-file',
        "static": true,
        content: 'Placeholder file name ...',
        field_name: 'download_',
        file_path: '',
        _href: ''
    }, {
        key: 'Range',
        name: 'Range',
        icon: 'fa fa-sliders',
        label: 'Placeholder Label',
        field_name: 'range_',
        step: 1,
        default_value: 3,
        min_value: 1,
        max_value: 5,
        min_label: 'Easy',
        max_label: 'Difficult'
    }, {
        key: 'Camera',
        name: 'Camera',
        icon: 'fa fa-camera',
        label: 'Placeholder Label',
        field_name: 'camera_'
    },
    {
        key: 'BoxNumber',
        element: 'CustomElement',
        component: MyInput,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'box_number_input_',
        name: 'Box Number',
        icon: 'fa fa-cog',
        props: {type: "text", className: "form-control"},
        label: 'Box Number - رقم الصندوق',
    },
    {
        key: 'NewBoxNumber',
        element: 'CustomElement',
        component: NewBoxNumberComponent,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'new_box_number_input_',
        name: 'New Box Number',
        icon: 'fa fa-cog',
        props: {type: "text", className: "form-control", id: "newBoxNumber"},
        label: 'رقم الصندوق الجديد',
    },
    {
        key: 'OldBoxNumber',
        element: 'CustomElement',
        component: OldBoxNumberComponentInput,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'old_box_number_input_',
        name: 'Old Box Number',
        icon: 'fa fa-cog',
        props: {type: "text", className: "form-control", id: "oldBoxNumber", disabled: true},
        label: 'رقم الصندوق القديم',
    },
    {
        key: 'ApproximatedAmount',
        element: 'CustomElement',
        component: ApproximatedAmountInput,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'approximated_amount_',
        name: 'Approximated Amount',
        icon: 'fa fa-cog',
        props: {type: "text", className: "form-control", id: "approximated", disabled: true},
        label: 'Approximated amount - المبلغ المقدر',
    },
    {
        key: 'walletNumber',
        element: 'CustomElement',
        component: WalletNumber,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'wallet_number_input_',
        name: 'Wallet Number',
        icon: 'fa fa-cog',
        props: {type: "number", className: "form-control", id: "walletNumber", disabled: true},
        label: 'رقم المحفظة الالكترونية',
    },
    {
        key: 'RadioButtons',
        canHaveAnswer: true,
        name: 'Old Box Status',
        icon: 'fa fa-dot-circle-o',
        label: 'Old Box Status - حالة الصندوق القديم',
        field_name: 'old_box_status_',
        options: []
    },
    {
        key: 'BoxSizeRadio',
        canHaveAnswer: true,
        element: 'CustomElement',
        component: CustomRadio,
        type: 'custom',
        forwardRef: true,
        name: 'Box Size',
        icon: 'fa fa-dot-circle-o',
        label: 'Box size - حجم الصندوق',
        field_name: 'box_size_',
        props:{inline:true,id:"box_size",disabled:true},
        options: [
            {
                value: "Small - صغير",
                text: "Small - صغير"
            },
            {
                value: "Medium - متوسط",
                text: "Medium - متوسط"
            },
            {
                value: "Large - كبير",
                text: "Large - كبير"
            }
        ]
    },    {
        key: 'BoxBaseRadio',
        canHaveAnswer: true,
        element: 'CustomElement',
        component: BoxBaseCustomRadio,
        type: 'custom',
        forwardRef: true,
        name: 'Box Base',
        icon: 'fa fa-dot-circle-o',
        label: 'Has Box Base? - هل يوجد قاعدة للصندوق؟',
        field_name: 'box_base_',
        props:{inline:true, id:"box_base", disabled:true},
        options: [
            {
                value: "Yes - نعم",
                text: "Yes - نعم"
            },
            {
                value: "No - لا",
                text: "No - لا"
            }
        ]
    },
    {
        key: 'BrinksVisitAmount',
        element: 'CustomElement',
        component: BrinksVisitComponent,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'brinks_visits_amount_input_',
        name: 'Brinks Visit Amount',
        icon: 'fa fa-cog',
        props: {type: "number", className: "form-control", id: "brinksVisitAmount", step: "any"},
        label: 'Brinks Visit Amount - تكلفة زيارة برنكس',
    },
    {
        key: 'BrinksCollectionAmount',
        element: 'CustomElement',
        component: BrinksCollectionComponent,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'brinks_collection_amount_input_',
        name: 'Brinks Collection Amount',
        icon: 'fa fa-cog',
        props: {type: "number", className: "form-control", id: "brinksCollectionAmount", step: "any"},
        label: 'Brinks Collection Amount - تكلفة تفريغ برنكس',
    },
    {
        key: 'BrinksTotalAmount',
        element: 'CustomElement',
        component: BrinksTotalAmount,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'brinks_total_amount_input_',
        name: 'Brinks Total Amount',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control",
            disabled: true,
            id: "brinksTotalAmount",
            step: "any",
            custom: {values: ["brinksVisitAmount", "brinksCollectionAmount"]}
        },
        label: 'Brinks Total Amount - اجمالي مبلغ برنكس',
    },
    {
        key: 'CountNumberOfOneJOD',
        element: 'CustomElement',
        component: TotalNumber,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'count_number_one_jod_input_',
        name: 'Count Number Of One JOD',
        icon: 'mdi mdi-coin',
        props: {
            defaultValue: 0,
            type: "number",
            className: "form-control",
            step: "any",
            custom: {weight: 1}
        },
        label: 'Count of One JOD - عدد الأوراق من عملة الدينار',
    },
    {
        key: 'TotalAmountInOneJOD',
        element: 'CustomElement',
        component: TotalAmount,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'total_amount_in_one_jod_input_',
        name: 'Total Amount of One JODs',
        icon: 'mdi mdi-coin',
        props: {
            defaultValue: 0,
            type: "number",
            step: "any",
            className: "form-control",
            disabled: true,
            id: "total_1",
            custom: {weight: 5}
        },
        label: 'Total Amount of One JODs - اجمالي المبلغ من عملة الدينار',
    },
    {
        key: 'CountNumberOfFiveJOD',
        element: 'CustomElement',
        component: TotalNumber,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'count_number_five_jod_input_',
        name: 'Count Number Of Five JOD',
        icon: 'mdi mdi-coin',
        props: {
            defaultValue: 0,
            type: "number",
            step: "any",
            className: "form-control",
            custom: {weight: 5}
        },
        label: 'Count of Five JODs - عدد الأوراق من عملة الخمسة دنانير',
    },
    {
        key: 'TotalAmountInFiveJOD',
        element: 'CustomElement',
        component: TotalAmount,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'total_amount_in_five_jod_input_',
        name: 'Total Amount of Five JODs',
        icon: 'mdi mdi-coin',
        props: {
            defaultValue: 0,
            type: "number",
            step: "any",
            className: "form-control",
            disabled: true,
            id: "total_5",
            custom: {weight: 5}
        },
        label: 'Total Amount of Five JODs - اجمالي المبلغ من عملة الخمسة دنانير',
    },
    {
        key: 'CountNumberOfTenJOD',
        element: 'CustomElement',
        component: TotalAmount,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'count_number_ten_jod_input_',
        name: 'Count Number Of Ten JOD',
        icon: 'mdi mdi-coin',
        props: {
            defaultValue: 0,
            type: "number",
            className: "form-control",
            step: "any",
            custom: {weight: 10}
        },
        label: 'Count of Ten JODs - عدد الأوراق من عملة عشرة دنانير',
    },
    {
        key: 'TotalAmountInTenJOD',
        element: 'CustomElement',
        component: TotalAmount,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'total_amount_in_ten_jod_input_',
        name: 'Total Amount of Ten JODs',
        icon: 'mdi mdi-coin',
        props: {
            defaultValue: 0,
            type: "number",
            step: "any",
            className: "form-control",
            disabled: true,
            id: "total_10",
            custom: {weight: 10}
        },
        label: 'Total Amount of Ten JODs - اجمالي المبلغ من عملة العشرة دنانير',
    },
    {
        key: 'CountNumberOfTwentyJOD',
        element: 'CustomElement',
        component: TotalAmount,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'count_number_twenty_jod_input_',
        name: 'Count Number Of Twenty JOD',
        icon: 'mdi mdi-coin',
        props: {
            defaultValue: 0,
            type: "number",
            className: "form-control",
            step: "any",
            custom: {weight: 20, totalTitle: "Total Amount of Twenty JODs - اجمالي المبلغ من عملة العشرين دينار"}
        },
        label: 'Count of Twenty JODs - عدد الأوراق من عملة عشرين دينار',
    },
    {
        key: 'TotalAmountInTwentyJOD',
        element: 'CustomElement',
        component: TotalAmount,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'total_amount_in_twenty_jod_input_',
        name: 'Total Amount of Twenty JODs',
        icon: 'mdi mdi-coin',
        props: {
            defaultValue: 0,
            type: "number",
            step: "any",
            className: "form-control",
            disabled: true,
            id: "total_20",
            custom: {weight: 20}
        },
        label: 'Total Amount of Twenty JODs - اجمالي المبلغ من عملة العشرين دينار',
    },
    {
        key: 'CountNumberOfFiftyJOD',
        element: 'CustomElement',
        component: TotalAmount,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'count_number_fifty_jod_input_',
        name: 'Count Number Of Fifty JOD',
        icon: 'mdi mdi-coin',
        props: {
            defaultValue: 0,
            type: "number",
            className: "form-control",
            step: "any",
            custom: {weight: 50}
        },
        label: 'Count of Fifty JODs - عدد الأوراق من عملة الخمسين دينار',
    },
    {
        key: 'TotalAmountInFiftyJOD',
        element: 'CustomElement',
        component: TotalAmount,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'total_amount_in_fifty_jod_input_',
        name: 'Total Amount of Fifty JODs',
        icon: 'mdi mdi-coin',
        props: {
            defaultValue: 0,
            type: "number",
            step: "any",
            disabled: true,
            className: "form-control",
            id: "total_50",
            custom: {weight: 50}
        },
        label: 'Total Amount of Fifty JODs - اجمالي المبلغ من عملة الخمسين دينار',
    },
    {
        key: 'TotalNumberOfPapers',
        element: 'CustomElement',
        component: PaperCurrencyCount,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'paper_currency_count_jod_input_',
        name: 'Count Number Of Paper Currency',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control",
            disabled: true,
            id: "total-paper",
            step: "any",
            custom: {values: ["total_1", "total_5", "total_10", "total_20", "total_50"]}
        },
        label: 'Total Paper Amount - اجمالي مبلغ العملات الورقية',
    },
    {
        key: 'TotalNumberOfCoins',
        element: 'CustomElement',
        component: CoinsCurrencyCount,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'coins_currency_count_jod_input_',
        name: 'Count Number Of Coins Currency',
        icon: 'mdi mdi-coin',
        props: {
            defaultValue:0,
            type: "number",
            step: "any",
            className: "form-control"
        },
        label: 'Total Coins Amount - اجمال مبلغ العملات النقدية',
    },
    {
        key: 'TotalNumberOfForeign',
        element: 'CustomElement',
        component: ForeignCurrencyCount,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_currency_count_jod_input_',
        name: 'Count Number Of Foreign Currency',
        icon: 'mdi mdi-coin',
        props: {
            defaultValue:0,
            type: "number",
            className: "form-control",
            step: "any",
        },
        label: 'Total Foreign Amount - اجمالي مبلغ العملات الأجنبية',
    },
    {
        key: 'TotalAmountInJod',
        element: 'CustomElement',
        component: TotalAmountInJOD,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'total_amount_in_jod_input_',
        name: 'Total Number In JOD',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control",
            disabled: true,
            id: "total-in-jod",
            step: "any",
            custom: {values: ["total-paper", "total_cions", "total_foreign"]}
        },
        label: 'Total Amount in JOD - اجمالي المبلغ بالدينار الأردني',
    },
    {
        key: 'Clients',
        canHaveAnswer: true,
        element: 'CustomElement',
        component: Clients,
        type: 'custom',
        forwardRef: true,
        name: 'Clients',
        icon: 'fa fa-caret-square-o-down',
        label: 'Client - العميل',
        props: {className: "form-control"},
        field_name: 'clients_',
        options: []
    },
    {
        key: 'Semesters',
        canHaveAnswer: true,
        element: 'CustomElement',
        component: Semesters,
        type: 'custom',
        forwardRef: true,
        name: 'Semesters',
        icon: 'fa fa-caret-square-o-down',
        label: 'الفصل الدراسي',
        props: {className: "form-control"},
        field_name: 'semesters_',
        options: []
    },
    {
        key: 'Cities',
        canHaveAnswer: true,
        element: 'CustomElement',
        component: Cities,
        type: 'custom',
        forwardRef: true,
        name: 'Cities',
        icon: 'fa fa-caret-square-o-down',
        label: 'City - المحافظة',
        props: {className: "form-control"},
        field_name: 'cities_',
        options: []
    },
    {
        key: 'Areas',
        canHaveAnswer: true,
        element: 'CustomElement',
        component: Areas,
        type: 'custom',
        forwardRef: true,
        name: 'Areas',
        icon: 'fa fa-caret-square-o-down',
        label: 'Area - المنطقة',
        props: {className: "form-control"},
        field_name: 'areas_',
        options: []
    },
    {
        key: 'ForeignCurrenciesControl',
        canHaveAnswer: true,
        element: 'CustomElement',
        component: ForeignCurrenciesControl,
        type: 'custom',
        forwardRef: true,
        name: 'ForeignCurrenciesControl',
        icon: 'fa fa-caret-square-o-down',
        label: 'Foreign Currencies Control',
        props: {className: "form-control"},
        field_name: 'currency_control_',
        options: []
    },
    {
        key: 'ForeignCurrenciesList1',
        canHaveAnswer: true,
        element: 'CustomElement',
        component: ForeignCurrenciesListComponent1,
        type: 'custom',
        forwardRef: true,
        name: 'ForeignCurrenciesList1',
        icon: 'fa fa-caret-square-o-down',
        label: 'Foreign Currencies List 1',
        props: {className: "form-control foreign-currency-list1 hidden"},
        field_name: 'currency_list_1',
        options: []
    },
    {
        key: 'ForeignCurrenciesList2',
        canHaveAnswer: true,
        element: 'CustomElement',
        component: ForeignCurrenciesListComponent2,
        type: 'custom',
        forwardRef: true,
        name: 'ForeignCurrenciesList2',
        icon: 'fa fa-caret-square-o-down',
        label: 'Foreign Currencies List 2',
        props: {className: "form-control foreign-currency-list2 hidden"},
        field_name: 'currency_list_2',
        options: []
    }, {
        key: 'ForeignCurrenciesList3',
        canHaveAnswer: true,
        element: 'CustomElement',
        component: ForeignCurrenciesListComponent3,
        type: 'custom',
        forwardRef: true,
        name: 'ForeignCurrenciesList3',
        icon: 'fa fa-caret-square-o-down',
        label: 'Foreign Currencies List 3',
        props: {className: "form-control foreign-currency-list3 hidden"},
        field_name: 'currency_list_3',
        options: []
    }, {
        key: 'ForeignCurrenciesList4',
        canHaveAnswer: true,
        element: 'CustomElement',
        component: ForeignCurrenciesListComponent4,
        type: 'custom',
        forwardRef: true,
        name: 'ForeignCurrenciesList4',
        icon: 'fa fa-caret-square-o-down',
        label: 'Foreign Currencies List 4',
        props: {className: "form-control foreign-currency-list5 hidden"},
        field_name: 'currency_list_5',
        options: []
    }, {
        key: 'ForeignCurrenciesList5',
        canHaveAnswer: true,
        element: 'CustomElement',
        component: ForeignCurrenciesListComponent5,
        type: 'custom',
        forwardRef: true,
        name: 'ForeignCurrenciesList5',
        icon: 'fa fa-caret-square-o-down',
        label: 'Foreign Currencies List 5',
        props: {className: "form-control foreign-currency-list5 hidden"},
        field_name: 'currency_list_5',
        options: []
    }, {
        key: 'ForeignCurrenciesList6',
        canHaveAnswer: true,
        element: 'CustomElement',
        component: ForeignCurrenciesListComponent6,
        type: 'custom',
        forwardRef: true,
        name: 'ForeignCurrenciesList6',
        icon: 'fa fa-caret-square-o-down',
        label: 'Foreign Currencies List 6',
        props: {className: "form-control foreign-currency-list6 hidden"},
        field_name: 'currency_list_6',
        options: []
    }, {
        key: 'ForeignCurrenciesList7',
        canHaveAnswer: true,
        element: 'CustomElement',
        component: ForeignCurrenciesListComponent7,
        type: 'custom',
        forwardRef: true,
        name: 'ForeignCurrenciesList7',
        icon: 'fa fa-caret-square-o-down',
        label: 'Foreign Currencies List 7',
        props: {className: "form-control foreign-currency-list7 hidden"},
        field_name: 'currency_list_7',
        options: []
    }, {
        key: 'ForeignCurrenciesList8',
        canHaveAnswer: true,
        element: 'CustomElement',
        component: ForeignCurrenciesListComponent8,
        type: 'custom',
        forwardRef: true,
        name: 'ForeignCurrenciesList8',
        icon: 'fa fa-caret-square-o-down',
        label: 'Foreign Currencies List 8',
        props: {className: "form-control foreign-currency-list8 hidden"},
        field_name: 'currency_list_8',
        options: []
    }, {
        key: 'ForeignCurrenciesList9',
        canHaveAnswer: true,
        element: 'CustomElement',
        component: ForeignCurrenciesListComponent9,
        type: 'custom',
        forwardRef: true,
        name: 'ForeignCurrenciesList9',
        icon: 'fa fa-caret-square-o-down',
        label: 'Foreign Currencies List 9',
        props: {className: "form-control foreign-currency-list9 hidden"},
        field_name: 'currency_list_',
        options: []
    }, {
        key: 'ForeignCurrenciesList10',
        canHaveAnswer: true,
        element: 'CustomElement',
        component: ForeignCurrenciesListComponent10,
        type: 'custom',
        forwardRef: true,
        name: 'ForeignCurrenciesList10',
        icon: 'fa fa-caret-square-o-down',
        label: 'Foreign Currencies List 10',
        props: {className: "form-control foreign-currency-list10 hidden"},
        field_name: 'currency_list_10',
        options: []
    }, {
        key: 'ForeignCurrenciesList11',
        canHaveAnswer: true,
        element: 'CustomElement',
        component: ForeignCurrenciesListComponent11,
        type: 'custom',
        forwardRef: true,
        name: 'ForeignCurrenciesList11',
        icon: 'fa fa-caret-square-o-down',
        label: 'Foreign Currencies List 11',
        props: {className: "form-control foreign-currency-list11 hidden"},
        field_name: 'currency_list_11',
        options: []
    }, {
        key: 'ForeignCurrenciesList12',
        canHaveAnswer: true,
        element: 'CustomElement',
        component: ForeignCurrenciesListComponent12,
        type: 'custom',
        forwardRef: true,
        name: 'ForeignCurrenciesList12',
        icon: 'fa fa-caret-square-o-down',
        label: 'Foreign Currencies List 12',
        props: {className: "form-control foreign-currency-list12 hidden"},
        field_name: 'currency_list_12',
        options: []
    },
    {
        key: 'ForeignCurrenciesList13',
        canHaveAnswer: true,
        element: 'CustomElement',
        component: ForeignCurrenciesListComponent13,
        type: 'custom',
        forwardRef: true,
        name: 'ForeignCurrenciesList13',
        icon: 'fa fa-caret-square-o-down',
        label: 'Foreign Currencies List 13',
        props: {className: "form-control foreign-currency-list13 hidden"},
        field_name: 'currency_list_13',
        options: []
    }, {
        key: 'ForeignCurrenciesList14',
        canHaveAnswer: true,
        element: 'CustomElement',
        component: ForeignCurrenciesListComponent14,
        type: 'custom',
        forwardRef: true,
        name: 'ForeignCurrenciesList14',
        icon: 'fa fa-caret-square-o-down',
        label: 'Foreign Currencies List 14',
        props: {className: "form-control foreign-currency-list14 hidden"},
        field_name: 'currency_list_14',
        options: []
    }, {
        key: 'ForeignCurrenciesList15',
        canHaveAnswer: true,
        element: 'CustomElement',
        component: ForeignCurrenciesListComponent15,
        type: 'custom',
        forwardRef: true,
        name: 'ForeignCurrenciesList15',
        icon: 'fa fa-caret-square-o-down',
        label: 'Foreign Currencies List 15',
        props: {className: "form-control foreign-currency-list15 hidden"},
        field_name: 'currency_list_15',
        options: []
    },
    {
        key: 'TotalAmountInJod1',
        element: 'CustomElement',
        component: ForeignTotal1,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_total_amount_in_jod_input_1',
        name: 'Total Number In JOD 1',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control total_amount_in_jod_input_1 hidden",
            id: "foreign-total-in-jod-1",
            step: "any",
        },
        label: 'Total Amount in JOD 1 - 1 اجمالي المبلغ بالدينار الأردني',
    },
    {
        key: 'TotalAmountInJod2',
        element: 'CustomElement',
        component: ForeignTotal2,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_total_amount_in_jod_input_2',
        name: 'Total Number In JOD 2',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control total_amount_in_jod_input_2 hidden",
            id: "foreign-total-in-jod-2",
            step: "any",
        },
        label: 'Total Amount in JOD 2 - 2 اجمالي المبلغ بالدينار الأردني',
    }, {
        key: 'TotalAmountInJod3',
        element: 'CustomElement',
        component: ForeignTotal3,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_total_amount_in_jod_input_3',
        name: 'Total Number In JOD 3',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control total_amount_in_jod_input_3 hidden",
            id: "foreign-total-in-jod-3",
            step: "any",
        },
        label: 'Total Amount in JOD 3 - 3 اجمالي المبلغ بالدينار الأردني',
    }, {
        key: 'TotalAmountInJod4',
        element: 'CustomElement',
        component: ForeignTotal4,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_total_amount_in_jod_input_4',
        name: 'Total Number In JOD 4',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control total_amount_in_jod_input_4 hidden",
            id: "foreign-total-in-jod-4",
            step: "any",
        },
        label: 'Total Amount in JOD 4 - 4 اجمالي المبلغ بالدينار الأردني',
    }, {
        key: 'TotalAmountInJod5',
        element: 'CustomElement',
        component: ForeignTotal5,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_total_amount_in_jod_input_5',
        name: 'Total Number In JOD 5',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control total_amount_in_jod_input_5 hidden",
            id: "foreign-total-in-jod-5",
            step: "any",
        },
        label: 'Total Amount in JOD 5 - 5 اجمالي المبلغ بالدينار الأردني',
    }, {
        key: 'TotalAmountInJod6',
        element: 'CustomElement',
        component: ForeignTotal6,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_total_amount_in_jod_input_6',
        name: 'Total Number In JOD 6',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control total_amount_in_jod_input_6 hidden",
            id: "foreign-total-in-jod-6",
            step: "any",
        },
        label: 'Total Amount in JOD 6 - 6 اجمالي المبلغ بالدينار الأردني',
    }, {
        key: 'TotalAmountInJod7',
        element: 'CustomElement',
        component: ForeignTotal7,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_total_amount_in_jod_input_7',
        name: 'Total Number In JOD 7',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control total_amount_in_jod_input_7 hidden",
            id: "foreign-total-in-jod-7",
            step: "any",
        },
        label: 'Total Amount in JOD 7 - 7 اجمالي المبلغ بالدينار الأردني',
    }, {
        key: 'TotalAmountInJod8',
        element: 'CustomElement',
        component: ForeignTotal8,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_total_amount_in_jod_input_8',
        name: 'Total Number In JOD 8',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control total_amount_in_jod_input_8 hidden",
            id: "foreign-total-in-jod-8",
            step: "any",
        },
        label: 'Total Amount in JOD 8 - 8 اجمالي المبلغ بالدينار الأردني',
    }, {
        key: 'TotalAmountInJod9',
        element: 'CustomElement',
        component: ForeignTotal9,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_total_amount_in_jod_input_9',
        name: 'Total Number In JOD 9',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control total_amount_in_jod_input_9 hidden",
            id: "foreign-total-in-jod-9",
            step: "any",
        },
        label: 'Total Amount in JOD 9 - 9 اجمالي المبلغ بالدينار الأردني',
    }, {
        key: 'TotalAmountInJod10',
        element: 'CustomElement',
        component: ForeignTotal10,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_total_amount_in_jod_input_10',
        name: 'Total Number In JOD 10',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control total_amount_in_jod_input_10 hidden",
            id: "foreign-total-in-jod-10",
            step: "any",
        },
        label: 'Total Amount in JOD 10 - 10 اجمالي المبلغ بالدينار الأردني',
    }, {
        key: 'TotalAmountInJod11',
        element: 'CustomElement',
        component: ForeignTotal11,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_total_amount_in_jod_input_11',
        name: 'Total Number In JOD 11',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control total_amount_in_jod_input_11 hidden",
            id: "foreign-total-in-jod-11",
            step: "any",
        },
        label: 'Total Amount in JOD 11 - 11 اجمالي المبلغ بالدينار الأردني',
    }, {
        key: 'TotalAmountInJod12',
        element: 'CustomElement',
        component: ForeignTotal12,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_total_amount_in_jod_input_12',
        name: 'Total Number In JOD 12',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control total_amount_in_jod_input_12 hidden",
            id: "foreign-total-in-jod-12",
            step: "any",
        },
        label: 'Total Amount in JOD 12 - 12 اجمالي المبلغ بالدينار الأردني',
    },
    {
        key: 'TotalAmountInJod13',
        element: 'CustomElement',
        component: ForeignTotal13,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_total_amount_in_jod_input_13',
        name: 'Total Number In JOD 13',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control total_amount_in_jod_input_13 hidden",
            id: "foreign-total-in-jod-13",
            step: "any",
        },
        label: 'Total Amount in JOD 13 - 13 اجمالي المبلغ بالدينار الأردني',
    },
    {
        key: 'TotalAmountInJod14',
        element: 'CustomElement',
        component: ForeignTotal14,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_total_amount_in_jod_input_14',
        name: 'Total Number In JOD 14',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control total_amount_in_jod_input_14 hidden",
            id: "foreign-total-in-jod-14",
            step: "any",
        },
        label: 'Total Amount in JOD 14 - 14 اجمالي المبلغ بالدينار الأردني',
    },
    {
        key: 'TotalAmountInJod15',
        element: 'CustomElement',
        component: ForeignTotal15,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_total_amount_in_jod_input_15',
        name: 'Total Number In JOD 15',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control total_amount_in_jod_input_15 hidden",
            id: "foreign-total-in-jod-15",
            step: "any",
        },
        label: 'Total Amount in JOD 15 - 15 اجمالي المبلغ بالدينار الأردني',
    },
    {
        key: 'ForeignCurrencyTransferRate1',
        element: 'CustomElement',
        component: ForeignCurrencyTransferRate1,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_currency_transfer_rate_1',
        name: 'Foreign Currency Transfer Rate 1',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control foreign_currency_transfer_rate_1 hidden",
            id: "foreign-currency-transfer-rate-1",
            step: "any",
        },
        label: 'Foreign Currency Transfer Rate 1 - 1 معدل تحويل العملات الأجنبية',
    },
    {
        key: 'ForeignCurrencyTransferRate2',
        element: 'CustomElement',
        component: ForeignCurrencyTransferRate2,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_currency_transfer_rate_2',
        name: 'Foreign Currency Transfer Rate 2',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control foreign_currency_transfer_rate_2 hidden",
            id: "foreign-currency-transfer-rate-2",
            step: "any",
        },
        label: 'Foreign Currency Transfer Rate 2 - 2 معدل تحويل العملات الأجنبية',
    },
    {
        key: 'ForeignCurrencyTransferRate3',
        element: 'CustomElement',
        component: ForeignCurrencyTransferRate3,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_currency_transfer_rate_3',
        name: 'Foreign Currency Transfer Rate 3',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control foreign_currency_transfer_rate_3 hidden",
            id: "foreign-currency-transfer-rate-3",
            step: "any",
        },
        label: 'Foreign Currency Transfer Rate 3 - 3 معدل تحويل العملات الأجنبية',
    },
    {
        key: 'ForeignCurrencyTransferRate4',
        element: 'CustomElement',
        component: ForeignCurrencyTransferRate4,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_currency_transfer_rate_4',
        name: 'Foreign Currency Transfer Rate 4',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control foreign_currency_transfer_rate_4 hidden",
            id: "foreign-currency-transfer-rate-4",
            step: "any",
        },
        label: 'Foreign Currency Transfer Rate 4 - 4 معدل تحويل العملات الأجنبية',
    },
    {
        key: 'ForeignCurrencyTransferRate5',
        element: 'CustomElement',
        component: ForeignCurrencyTransferRate5,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_currency_transfer_rate_5',
        name: 'Foreign Currency Transfer Rate 5',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control foreign_currency_transfer_rate_5 hidden",
            id: "foreign-currency-transfer-rate-5",
            step: "any",
        },
        label: 'Foreign Currency Transfer Rate 5 - 5 معدل تحويل العملات الأجنبية',
    },
    {
        key: 'ForeignCurrencyTransferRate6',
        element: 'CustomElement',
        component: ForeignCurrencyTransferRate6,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_currency_transfer_rate_6',
        name: 'Foreign Currency Transfer Rate 6',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control foreign_currency_transfer_rate_6 hidden",
            id: "foreign-currency-transfer-rate-6",
            step: "any",
        },
        label: 'Foreign Currency Transfer Rate 6 - 6 معدل تحويل العملات الأجنبية',
    },
    {
        key: 'ForeignCurrencyTransferRate7',
        element: 'CustomElement',
        component: ForeignCurrencyTransferRate7,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_currency_transfer_rate_7',
        name: 'Foreign Currency Transfer Rate 7',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control foreign_currency_transfer_rate_7 hidden",
            id: "foreign-currency-transfer-rate-7",
            step: "any",
        },
        label: 'Foreign Currency Transfer Rate 7 - 7 معدل تحويل العملات الأجنبية',
    },
    {
        key: 'ForeignCurrencyTransferRate8',
        element: 'CustomElement',
        component: ForeignCurrencyTransferRate8,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_currency_transfer_rate_8',
        name: 'Foreign Currency Transfer Rate 8',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control foreign_currency_transfer_rate_8 hidden",
            id: "foreign-currency-transfer-rate-8",
            step: "any",
        },
        label: 'Foreign Currency Transfer Rate 8 - 8 معدل تحويل العملات الأجنبية',
    },
    {
        key: 'ForeignCurrencyTransferRate9',
        element: 'CustomElement',
        component: ForeignCurrencyTransferRate9,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_currency_transfer_rate_9',
        name: 'Foreign Currency Transfer Rate 9',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control foreign_currency_transfer_rate_9 hidden",
            id: "foreign-currency-transfer-rate-9",
            step: "any",
        },
        label: 'Foreign Currency Transfer Rate 9 - 9 معدل تحويل العملات الأجنبية',
    },
    {
        key: 'ForeignCurrencyTransferRate10',
        element: 'CustomElement',
        component: ForeignCurrencyTransferRate10,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_currency_transfer_rate_10',
        name: 'Foreign Currency Transfer Rate 10',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control foreign_currency_transfer_rate_10 hidden",
            id: "foreign-currency-transfer-rate-10",
            step: "any",
        },
        label: 'Foreign Currency Transfer Rate 10 - 10 معدل تحويل العملات الأجنبية',
    },
    {
        key: 'ForeignCurrencyTransferRate11',
        element: 'CustomElement',
        component: ForeignCurrencyTransferRate11,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_currency_transfer_rate_11',
        name: 'Foreign Currency Transfer Rate 11',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control foreign_currency_transfer_rate_11 hidden",
            id: "foreign-currency-transfer-rate-11",
            step: "any",
        },
        label: 'Foreign Currency Transfer Rate 11 - 11 معدل تحويل العملات الأجنبية',
    },
    {
        key: 'ForeignCurrencyTransferRate12',
        element: 'CustomElement',
        component: ForeignCurrencyTransferRate12,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_currency_transfer_rate_12',
        name: 'Foreign Currency Transfer Rate 12',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control foreign_currency_transfer_rate_12 hidden",
            id: "foreign-currency-transfer-rate-12",
            step: "any",
        },
        label: 'Foreign Currency Transfer Rate 12 - 12 معدل تحويل العملات الأجنبية',
    },
    {
        key: 'ForeignCurrencyTransferRate13',
        element: 'CustomElement',
        component: ForeignCurrencyTransferRate13,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_currency_transfer_rate_13',
        name: 'Foreign Currency Transfer Rate 13',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control foreign_currency_transfer_rate_13 hidden",
            id: "foreign-currency-transfer-rate-13",
            step: "any",
        },
        label: 'Foreign Currency Transfer Rate 13 - 13 معدل تحويل العملات الأجنبية',
    },
    {
        key: 'ForeignCurrencyTransferRate14',
        element: 'CustomElement',
        component: ForeignCurrencyTransferRate14,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_currency_transfer_rate_14',
        name: 'Foreign Currency Transfer Rate 14',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control foreign_currency_transfer_rate_14 hidden",
            id: "foreign-currency-transfer-rate-14",
            step: "any",
        },
        label: 'Foreign Currency Transfer Rate 14 - 14 معدل تحويل العملات الأجنبية',
    },
    {
        key: 'ForeignCurrencyTransferRate15',
        element: 'CustomElement',
        component: ForeignCurrencyTransferRate15,
        type: 'custom',
        forwardRef: true,
        canReadOnly: true,
        field_name: 'foreign_currency_transfer_rate_15',
        name: 'Foreign Currency Transfer Rate 15',
        icon: 'mdi mdi-coin',
        props: {
            type: "number",
            className: "form-control foreign_currency_transfer_rate_15 hidden",
            id: "foreign-currency-transfer-rate-15",
            step: "any",
        },
        label: 'Foreign Currency Transfer Rate 15 - 15 معدل تحويل العملات الأجنبية',
    },
    {
        key: 'RadioButtonsWithdrawal',
        element: 'CustomElement',
        component: Withdrawal,
        type: 'custom',
        forwardRef: true,
        canHaveAnswer: true,
        name: 'Withdrawal Multiple Choice',
        icon: 'fa fa-dot-circle-o',
        label: 'Withdrawal? - هل تم سحب الصندوق؟',
        field_name: 'radiobuttons_withdrawal',
        props: {
            className: "form-control custom-control-input",
            custom: {targetId: "newBoxNumber", id: "withdrawal", hideValue: "Yes", showValue: "No", action: "hide"}
        },
        options: [
            {
                value: "Yes",
                text: "Yes - نعم"
            },
            {
                value: "No",
                text: "No - لا"
            }
        ]
    },
    {
        key: 'BrinksRadioButtonsWithdrawal',
        element: 'CustomElement',
        component: BrinksWithdrawal,
        type: 'custom',
        forwardRef: true,
        canHaveAnswer: true,
        name: 'Visited By Brinks Multiple Choice',
        icon: 'fa fa-dot-circle-o',
        label: 'Visited By Brinks - هل تم زيارة الموقع من قبل برنكس؟',
        field_name: 'radiobuttons_brinks_visited_',
        props: {
            className: "form-control custom-control-input",
            custom: {
                targetId: "brinksVisitAmount",
                id: "brinksVisited",
                hideValue: "No",
                showValue: "Yes",
                action: "change",
                targetValue: 0
            }
        },
        options: [
            {
                value: "Yes",
                text: "Yes - نعم"
            },
            {
                value: "No",
                text: "No - لا"
            }
        ]
    },
    {
        key: 'Attendance',
        canHaveAnswer: true,
        element: 'CustomElement',
        component: Attendance,
        type: 'custom',
        forwardRef: true,
        name: 'Attendance',
        icon: 'fa fa-caret-square-o-down',
        label: 'Attendance - الحضور',
        props: {className: "form-control"},
        field_name: 'Attendance_',
        options: []
    },
        {
                     key: 'MainProfRepValue',
                     element: 'CustomElement',
                     component: MainProfRepFieldValue,
                     type: 'custom',
                     forwardRef: true,
                     canReadOnly: true,
                     field_name: 'representation_filed_value',
                     name: 'Main profile representation value',
                     icon: 'fa fa-cog',
                     props: {type: "text", className: "form-control", id: "rep-field-val", disabled: true},
                     label: 'Arabic Full Name - الاسم الرباعي باللغة العربية',
                 },

                         {
                                      key: 'MainDonorName',
                                      element: 'CustomElement',
                                      component: MainDonorNameInput,
                                      type: 'custom',
                                      forwardRef: true,
                                      canReadOnly: true,
                                      field_name: 'main_donor_name_',
                                      name: 'Main donor name',
                                      icon: 'fa fa-cog',
                                      props: {type: "text", className: "form-control", id: "rep-field-val", disabled: true},
                                      label: 'Donor Name -حركات اسم المتبرع',
                                  }
];

export {
    formBuilderItmes,
    MyInput,
    TotalAmount,
    TotalNumber,
    CoinsCurrencyCount,
    TotalAmountInJOD,
    ForeignCurrencyCount,
    PaperCurrencyCount,
    Clients,
    Semesters,
    Cities,
    Areas,
    Withdrawal,
    BrinksWithdrawal,
    NewBoxNumberComponent,
    BrinksVisitComponent,
    BrinksCollectionComponent,
    NextCollectionDate,
    FromDate,
    ToDate,
    OldBoxNumberComponentInput,
    BrinksTotalAmount,
    ForeignCurrenciesControl,
    ForeignCurrenciesListComponent1,
    ForeignCurrenciesListComponent2,
    ForeignCurrenciesListComponent3,
    ForeignCurrenciesListComponent4,
    ForeignCurrenciesListComponent5,
    ForeignCurrenciesListComponent6,
    ForeignCurrenciesListComponent7,
    ForeignCurrenciesListComponent8,
    ForeignCurrenciesListComponent9,
    ForeignCurrenciesListComponent10,
    ForeignCurrenciesListComponent11,
    ForeignCurrenciesListComponent12,
    ForeignCurrenciesListComponent13,
    ForeignCurrenciesListComponent14,
    ForeignCurrenciesListComponent15,
    ForeignTotal1,
    ForeignTotal2,
    ForeignTotal3,
    ForeignTotal4,
    ForeignTotal5,
    ForeignTotal6,
    ForeignTotal7,
    ForeignTotal8,
    ForeignTotal9,
    ForeignTotal10,
    ForeignTotal11,
    ForeignTotal12,
    ForeignTotal13,
    ForeignTotal14,
    ForeignTotal15,
    ForeignCurrencyTransferRate1,
    ForeignCurrencyTransferRate2,
    ForeignCurrencyTransferRate3,
    ForeignCurrencyTransferRate4,
    ForeignCurrencyTransferRate5,
    ForeignCurrencyTransferRate6,
    ForeignCurrencyTransferRate7,
    ForeignCurrencyTransferRate8,
    ForeignCurrencyTransferRate9,
    ForeignCurrencyTransferRate10,
    ForeignCurrencyTransferRate11,
    ForeignCurrencyTransferRate12,
    ForeignCurrencyTransferRate13,
    ForeignCurrencyTransferRate14,
    ForeignCurrencyTransferRate15,
    CustomRadio,
    BoxBaseCustomRadio,
    WalletNumber,
    Attendance,
    MainProfRepFieldValue,
    MainDonorNameInput,
    ApproximatedAmountInput,
    DoubleNumberInput
};