import React from "react";
import ID from "./ID";

export default class WithdrawalRadioButtons extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: null
        }
        this.onChangeValue = this.onChangeValue.bind(this);

    }

    onChangeValue(event) {
        const val = event.target.value;
        this.setState({
            value: val
        })

        let newBoxNumberElement = document.getElementById(this.props.custom.targetId);
        if (val === this.props.custom.showValue && newBoxNumberElement) {
            newBoxNumberElement.parentNode.classList.remove('hidden')
        } else if (val === this.props.custom.hideValue && newBoxNumberElement) {
            if (this.props.custom.action == "hide") {
                newBoxNumberElement.parentNode.classList.add('hidden')
            } else if (this.props.custom.action == "change") {
                newBoxNumberElement.value = this.props.custom.targetValue
            }
        }
    }

    componentDidMount() {
        let newBoxNumberElement = document.getElementById(this.props.custom.targetId);
        let val = this.state.value ? this.state.value : this.props.defaultValue ? this.props.defaultValue : "";
        if (val === this.props.custom.showValue && newBoxNumberElement) {
            if (this.props.custom.action == "hide") {
                newBoxNumberElement.parentNode.classList.remove('hidden')
            }
        }
    }

    render() {
        let self = this;

        const props = {};
        props.name = self.props.data.field_name;

        let value = this.state.value ? this.state.value : self.props.defaultValue ? self.props.defaultValue : "";

        let newBoxNumberElement = document.getElementById(this.props.custom.targetId);
        if (value === this.props.custom.hideValue && newBoxNumberElement) {
            if (this.props.custom.action == "hide") {
                newBoxNumberElement.parentNode.classList.add('hidden')
            } else if (this.props.custom.action == "change") {
                newBoxNumberElement.value = this.props.custom.targetValue
            }
        }
        props.value = value;

        props.defaultChecked = (self.props.defaultValue !== undefined &&
            (self.props.defaultValue.indexOf(this.state.value) > -1));

        console.log("main props", this.props)

        let disabledProps = {};
        disabledProps.disabled = this.props.disabled;

        return <>

            <input type={"hidden"} ref={this.props.ref} {...props} id={this.props.custom.id}/>

            <div onChange={this.onChangeValue}>
                {this.props.data.options.map((option) => {
                    let key = ID.uuid();
                    const this_key = `${this.props.custom.id}_preview_${key}`;
                    let dc = (value &&
                        (value.indexOf(option.value) > -1))
                    return (
                        <div key={this_key} className={"custom-control custom-radio"}>
                            <input id={"fid_" + this_key} className="custom-control-input" type={'radio'}
                                   {...disabledProps}
                                   value={option.value} name={this.props.custom.id + "_withdrawal-opt"} defaultChecked={dc}/>
                            <label className="custom-control-label" htmlFor={"fid_" + this_key}>{option.text}</label>
                        </div>
                    );
                })}
            </div>
        </>;
    }
}