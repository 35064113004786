import React from "react";

export default class CustomizedInput extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        return <>
            <input {...this.props} />
        </>;
    }
}