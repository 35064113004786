import React from "react";
import {_getOnValueParams} from "../../../../services/FireBaseClientService";
import ID from "./ID";
import $ from 'jquery';

export default class SemestersDropDownList extends React.Component {


    constructor(props) {
        super(props)
        this.state = {
            semesters: [],
        }
    }

    componentDidMount() {
        _getOnValueParams('/api/getData', {query: 'semesters', params: '\{\}'}, (res) => {
            this.setState({
                semesters: res.data.data.semesters
            })
        })
    }

    onChange(e) {
        let option = $(e.target).find("option[value='"+e.target.value+"']");
        $('#fromDate').val(option.data("from"))
        $('#toDate').val(option.data("to"))
    }


    render() {
        let currentValue = this.props.defaultValue;
        let client = currentValue && this.state.semesters ? this.state.semesters.filter(c => c.semester == currentValue)[0] : {};
        return (
            <>
                <select {...this.props} onChange={this.onChange} id={"semesters_list"}>
                    <option value="" key={0}></option>
                    {this.state.semesters && this.state.semesters.map((tClient) => {
                        const this_key = `preview_${ID.uuid()}`;
                        return <option value={tClient.semester} key={this_key}
                                       data-from={tClient.fromDate}
                                       data-to={tClient.toDate}
                                       selected={client.semester == tClient.semester}>{tClient.description}</option>;
                    })}
                </select>
            </>
        );
    }
}